import { dialogController } from '@sydle/sydle-ui';
import { t } from './translation-utils';
import type { CreateDialogOptions } from '@sydle/sydle-ui/dist/types/components/dialog/dialog-controller';

export function createDialogLogin(
  dialogCreateOptions?: Partial<CreateDialogOptions>,
): ReturnType<typeof dialogController.create> {
  const dialog = dialogController.create({
    header: {
      dialogTitle: t('AUTHENTICATION_TITLE', 'Authentication'),
    },
    dialogSize: 'small',
    content: {
      slotComponent: 'sy-app-login',
      styles: {
        '--sy-dialog-content-margin': '0',
        '--sy-dialog-content-padding-top': '0',
        '--sy-dialog-content-padding-bottom': '0',
        '--sy-dialog-content-padding-left': '0',
        '--sy-dialog-content-padding-right': '0',
        '--sy-app-login-box-shadow': '0',
      },
    },
    footer: {},
    hideFooter: true,
    stylesDialog: {
      '--sy-dialog-size-sm': '368px',
    },
    ...dialogCreateOptions,
  });

  return dialog;
}
